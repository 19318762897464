// TODO: rename the file to UserPromoCodes

import React, { useState, useCallback, useRef } from "react";
import BEMHelper from "react-bem-helper";
import {
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  Toast,
  ToastHeader,
  ToastBody,
  Badge,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "@/actions";

import { Button } from "bokamera-embedded-ui";

import "./CustomerPromoCodes.css";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { I18n } from "@lingui/core";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useGetRebateCodesQuery } from "@/services/bmApi";

const c = new BEMHelper({
  name: "CustomerPromoCodes",
});

const RebateCodeType: React.FC<{ rebateCodeTypeId?: number }> = ({ rebateCodeTypeId: rebateCodeId }) => {
  switch (rebateCodeId) {
      case 1:
          return <Badge color="secondary"><Trans id="CustomerPromoCodes.articleTypeId.1.text" /></Badge>;
      case 2:
          return <Badge color="secondary"><Trans id="CustomerPromoCodes.articleTypeId.2.text" /></Badge>;
      case 3:
          return <Badge color="secondary"><Trans id="CustomerPromoCodes.articleTypeId.3.text" /></Badge>;
      case 4:
          return <Badge color="secondary"><Trans id="CustomerPromoCodes.articleTypeId.4.text" /></Badge>;
      case 5:
          return <Badge color="secondary"><Trans id="CustomerPromoCodes.articleTypeId.5.text" /></Badge>;
      default:
          return null
  }
};


export const CustomerPromoCodes: React.FC<{ i18n: I18n }> = ({ i18n }) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const promoCodes = useAppSelector((s) => s.promoCodes);
  const companyId = useAppSelector((s) => s.company.data?.Id);
  const customerId = useAppSelector((state) => state.user.customerId);
  const selectedService = useAppSelector((state) => state.booking.service);
  const rebateCodes = useGetRebateCodesQuery({ CustomerId: customerId, CompanyId: companyId });
  const applicedCodes = useAppSelector(state => state.prices?.data?.AppliedCodes || [])
  const prices = selectedService?.Prices ? selectedService.Prices : [];
  const calculatingPrice = useAppSelector(state => state.prices?.isLoading);

  return (
    <div {...c()}>
      {rebateCodes.data?.Results.map((rebateCode) => {
        const rebateCodeActivated = applicedCodes.find(
          (appliedCode) =>
            appliedCode.RebateCodeSign === rebateCode.RebateCodeSign
        );

        console.log('1',

          promoCodes.requestedCode,
          rebateCode.RebateCodeSign,
          (promoCodes.isLoading || calculatingPrice)
        )
        
        return (
          <div>
            <Card {...c("card", "", "column")}>
              <CardBody {...c("flex", "", "full-width")}>
                <div {...c("left")}>
                  <RebateCodeType
                    rebateCodeTypeId={rebateCode.RebateCodeTypeId}
                  />
                  <CardTitle>{rebateCode.RebateCodeSign}</CardTitle>
                  <CardText>
                    <Trans id="CustomerPromoCodes.usedOutOf" />
                    {rebateCode.NumberOfUsesUsed} / {rebateCode.MaxNumberOfUses}
                  </CardText>
                  {rebateCode.RebateCodeTypeId !== 3 ? (
                    <CardText>
                      <Trans id="CustomerPromoCodes.value" />
                      {rebateCode.RebateCodeValue}
                      {prices[0].PriceSign}
                    </CardText>
                  ) : null}
                </div>

                <div {...c("right")}>
                  <Button
                    disabled={
                      promoCodes.requestedCode === rebateCode.RebateCodeSign &&
                      (promoCodes.isLoading || calculatingPrice)
                    }
                    onClick={(evt) => {
                      evt.preventDefault();
                      if (!rebateCodeActivated) {
                        dispatch(
                          actions.applyPromoCodeAsync.request({
                            RebateCodeSign: rebateCode.RebateCodeSign as string,
                          })
                        );
                      } else {
                        dispatch(
                          actions.removePromoCodeAsync.request({
                            RebateCodeSign: rebateCode.RebateCodeSign as string,
                          })
                        );
                      }
                    }}
                  >
                    {rebateCodeActivated ? (
                      <Trans id="remove" />
                    ) : (
                      <Trans id="apply" />
                    )}
                  </Button>
                </div>
              </CardBody>

              {promoCodes?.error?._error &&
              promoCodes.requestedCode === rebateCode.RebateCodeSign ? (
                <CardBody>
                  <Toast className="text-danger border border-danger p-3 rounded">
                    <ToastHeader>{promoCodes.error._error}</ToastHeader>
                  </Toast>
                </CardBody>
              ) : null}
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default withI18n()(CustomerPromoCodes);
