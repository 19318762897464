import { AnyAction, ListenerEffect } from "@reduxjs/toolkit";
import axios from "axios";
import { ApplicationDispatch, ApplicationState } from "@/store";
import { clearCustomer } from "../customer";
import {
  redirectAfterLogin,
} from "@/actions";
import keycloak, { keycloakConfig } from "@/keycloak";
import qs from "qs";
import { BACK_URI } from "@/providers/IdentityProvider";
import { authenticateSuccess } from ".";
import { replace } from "connected-react-router";
import { persistor } from "@/store";

export const redirectAfterLoginEffectEmbed: ListenerEffect<
  AnyAction,
  ApplicationState,
  ApplicationDispatch
> = async (action, api) => {
  try {
    if (redirectAfterLogin.match(action)) {
        persistor.persist();
        const configuration = api.getState().configuration.data;
        const targetOrigin = configuration?.targetOrigin
          ? configuration?.targetOrigin
          : configuration?._targetOrigin;

        const parsedSearch = qs.parse(window.location.hash.split("?")[1], {
          ignoreQueryPrefix: true,
        }) as {
          code?: string;
        };
        const { code } = parsedSearch;

        const backUri = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        })[BACK_URI]
          ? qs.parse(window.location.search, { ignoreQueryPrefix: true })[
              BACK_URI
            ]
          : window.location.href;

        const backUriWithState = new URL(backUri as string);

        let authData: any;
        if (code) {
          authData = await axios({
            url: keycloakConfig.tokenEndpoint,
            method: "POST",
            data: qs.stringify({
              grant_type: "authorization_code",
              code,
              client_id: keycloakConfig.clientId,
              redirect_uri: backUriWithState.href,
            }),
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }).then((r) => r.data);

          keycloak.authenticated = true;
          keycloak.token = authData.access_token;
          keycloak.refreshToken = authData.refresh_token;
          keycloak.sessionId = authData.session_state;

          const redirectTo = api.getState().authenticate.state?.redirectTo!;
          
          const url = new URL(window.location.origin);
          url.hash = redirectTo as string;
          url.search = window.location.search;
  
          window.history.pushState({}, "", url.href);
  
          api.dispatch(replace(redirectTo as string));
          
          window.parent.postMessage({ login: true }, targetOrigin || "*");
          api.dispatch(authenticateSuccess());
          persistor.flush();
          persistor.persist();
        }
       

    }
  } catch (error) {
    console.error(error);
  }
};

export const redirectAfterLoginEffectDirect: ListenerEffect<
  AnyAction,
  ApplicationState,
  ApplicationDispatch
> = async (action, api) => {
  try {
    // TODO: login and show last sreen after login
    if (redirectAfterLogin.match(action)) {
        persistor.persist();
        const parsedSearch = qs.parse(window.location.hash.split("?")[1], {
          ignoreQueryPrefix: true,
          parseArrays: true,
        }) as {
          state?: string;
          session_state?: string;
          code?: string;
        };

        const { code } = parsedSearch;
        delete parsedSearch.state;
        delete parsedSearch.session_state;
        delete parsedSearch.code;
        
        if (code) {
          await keycloak.init({ checkLoginIframe: false })
          keycloak.authenticated = true;
        }
        const redirectTo = api.getState().authenticate.state?.redirectTo!;
        const stringifiedSearch = qs.stringify(parsedSearch);
        const url = new URL(window.location.origin);
        url.hash = redirectTo as string;
        url.search = stringifiedSearch;
        window.history.pushState("", "", url.href);
        api.dispatch(replace(redirectTo));
        api.dispatch(clearCustomer());
        persistor.persist();
    }
  } catch (error) {
    console.error(error);
  }
};
