import { takeEvery, all } from "redux-saga/effects";
import {
  book,
  createCheckout,
  fetchCompany,
  fetchServices,
  fetchServicesTimes,
  fetchTimesWatcher,
  logout,
  resetForm,
  selectPrevNextCalendar,
  selectNextAvailableTime,
  selectResourceSaga,
  selectService,
  selectTime,
  selectTimesDay,
  applyPromoCode,
  calculatePrice,
  createAccount,
  resetParentConfig,
  fillCustomerForm,
  startOver,
  removePromoCode
} from "./sagas";
import { applyPromoCodeAsync, removePromoCodeAsync, RESET_PARENT_CONFIG } from "@/actions";

export function* sagas() {
  yield all([
    takeEvery("FETCH_SERVICES", fetchServices),
    takeEvery("FETCH_COMPANY_REQUEST", fetchCompany),
    takeEvery("FETCH_TIMES", fetchTimesWatcher),
    takeEvery("FETCH_FLEXIBLE_TIMES", fetchTimesWatcher),
    takeEvery("FETCH_SERVICES_TIMES", fetchServicesTimes),
    takeEvery("RESET_FORM", resetForm),
    takeEvery("SELECT_SERVICE", selectService),
    takeEvery("SELECT_RESOURCE", selectResourceSaga),
    takeEvery("SELECT_TIME", selectTime),
    takeEvery("SELECT_TIMES_DAY", selectTimesDay),
    takeEvery("SELECT_PREV_CALENDAR", selectPrevNextCalendar),
    takeEvery("SELECT_NEXT_CALENDAR", selectPrevNextCalendar),
    takeEvery("SELECT_NEXT_AVAILABLE_TIME", selectNextAvailableTime),
    takeEvery("CREATE_ACCOUNT_REQUEST", createAccount),
    takeEvery("LOGOUT", logout),
    takeEvery('SAVE_AUTHENTICATION', fillCustomerForm),

    takeEvery("BOOK", book),

    takeEvery("FORM_ERROR", () => console.log("Form submission error")),
    // Checkout
    takeEvery("CREATE_CHECKOUT_REQUEST", createCheckout),
    takeEvery("APPLY_PROMO_CODE_REQUEST", applyPromoCode),
    takeEvery("REMOVE_PROMO_CODE_REQUEST", removePromoCode),
    takeEvery(
      ["CALCULATE_PRICE_REQUEST", "CHANGE_QUANTITY", "SELECT_PRICE"],
      calculatePrice
    ),
    takeEvery('START_OVER', startOver),
    takeEvery(RESET_PARENT_CONFIG, resetParentConfig)
  ]);
}
